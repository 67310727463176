body {
    font-family: 'Lato', sans-serif;
}

a {
    color: #0274be;
    &:hover {
        text-decoration: none;
        color: #3a3a3a;
    }
}

.main-header-bar {
	box-shadow: 0px 3px 3px #b8b8b8;
	padding: 60px 0px 60px 0px;
	background-image: url("/assets/images/unimprobable-solutions-banner.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
h1, h2, h3 {
    font-family: 'Roboto', sans-serif;
}
footer {
    color: #eaeaea;
    a {
        color: #eaeaea;
        &:visited {
            color: gray;
        }
        &:hover {
            color: white;
            text-decoration: none;
        }
    }
    h2 {
        font-size: 1.5rem;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
}
#sidebar {
    ul {
        list-style-type: none;
        padding: 0;
    }
}